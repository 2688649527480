.gallery .gallery-text {
  font-family: "Roboto";
  text-align: center;
  font-size: 19px;
}

.gallery .gallery-img {
  height: 230px;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  border-radius: 10px;
}

.gallery .gallery-headline {
  text-align: center;
  font-size: 20px;
  font-family: "Roboto";
}
