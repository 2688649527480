.home-about {
  font-size: 18px;
  font-family: "Roboto";
  padding: 40px 0;
}

.home-about .about-img {
  border-radius: 25px;
}

.home-about .title {
  font-weight: 500;
  font-size: 26px;
}

.home-about .vision {
  font-weight: 500;
  font-size: 24px;
}

.home-about .vision-text {
  font-size: 20px;
  text-align: justify;
}
