.footer {
  background-color: #171c26;
  padding: 40px 0 40px 0;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.footer img {
  padding-left: 20px;
}

@media all and (max-width: 480px) {
  .footer img {
    width: 30%;
  }
}
