.home-services {
  background-color: #f5f8fd;
  padding: 40px 0;
}

.home-services .services-headline {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  font-family: "Roboto";
}

.home-services .areas-of-practise-header {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
}

.btn-capability {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  border-radius: 100px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  background-color: #5290e2 !important;
  border: none !important;
  color: #fff !important;
}
