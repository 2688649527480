.service-page p {
  font-family: "Roboto";
  font-size: 18px;
}

.service-page .title {
  font-weight: 500;
  font-size: 20px;
}

.service-page img {
  border-radius: 25px;
}

@media all and (max-width: 480px) {
  .service-page {
    height: 100%;
  }
}
