.home-services-box .box .home-services-box-title {
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
  color: #000;
  margin: 0;
}

.home-services-box .box {
  background-color: #fff !important;
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  text-align: center;
}
