.landing {
  background-image: url("../../assets/bg.jpg");
  font-family: "Roboto";
  min-height: 100vh;
  background-size: cover;
}

.landing .landing-nav {
  color: #fff !important;
}

.landing .landing-con {
  margin-top: 11rem;
}

.landing .headline {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 48px;
  margin: 0 !important;
  text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.6);
  color: #fff;
  line-height: 1.2;
}

.landing .sub-headline {
  font-family: "Montserrat";
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.6);
  color: #fff;
  line-height: 1.4;
}

.landing .btn-landing-contact {
  width: 150px;
  background-color: #5290e2 !important;
  color: #fff;
  border-radius: 25px;
  font-size: 16px;
  border: none;
}

@media all and (max-width: 480px) {
  .landing .landing-con {
    margin-top: 4rem;
  }

  .landing .headline {
    font-size: 32px;
    text-align: center;
  }

  .landing .sub-headline {
    font-size: 20px;
    text-align: center;
  }

  .landing .mobile-center {
    text-align: center;
  }
}
