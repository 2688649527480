.management-profile {
  font-family: "Roboto";
  font-size: 18px;
}

.management-profile .name {
  font-weight: 500;
  font-size: 22px;
  margin: 0;
}

.management-profile .job {
  font-weight: 500;
  font-size: 20px;
}

.management-profile .sub-title {
  font-weight: 500;
  margin: 0;
}

.management-profile-img {
  width: 100%;
  border-radius: 15px;
}

.management-profile .line {
  border-bottom: 2px solid;
  width: 350px;
}

.management-profile .line-training {
  border-bottom: 2px solid;
  width: 225px;
}

.management-profile .alt-line {
  border-bottom: 2px solid;
  width: 85px;
}

.page-height {
  min-height: 80vh;
}

@media all and (max-width: 480px) {
  .management-profile .line {
    border-bottom: 2px solid;
    width: 100%;
  }
}
