.navbar-dark .nav-item .nav-link {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #413e66 !important;
}

.navbar-light .nav-item .nav-link {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #fff !important;
}

.navbar-brand {
  color: #fff !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2.2rem !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.navbar-dark .navbar-toggler {
  border-color: transparent !important;
}

.navbar-light .navbar-toggler-icon {
  color: #1da1f2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-dark .navbar-toggler-icon {
  color: #1da1f2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(65, 62, 102)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

a:hover {
  text-decoration: none !important;
  cursor: pointer !important;
}

@media all and (max-width: 480px) {
  .navbar-dark .nav-item .nav-link {
    color: #000 !important;
  }
}
