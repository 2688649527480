.profile .management-img {
  border-radius: 15px;
}

.profile .management-name {
  font-size: 19px;
  font-weight: 500;
  padding-top: 8px;
  margin: 0;
}
